export const HOME = '/';
export const LOGIN = '/login';
export const QUIZ = '/quiz';
export const STATISTICS_QUIZ = '/quiz/statistics';
export const CREATE_QUIZ = '/quiz/criar';
export const QUESTION = '/quiz/questoes/';
export const TOKENEXPIRED = '/expire';
export const CLASSES = '/turmas';
export const MANAGE_CLASSES = '/geranciar-turmas';
export const CREATE_CLASS = '/turmas/criar';
export const FAQ = '/faq';
